import React from 'react';
import * as axios from 'axios'
import logo from './logo.svg';
import './App.css';

export default class App extends React.Component {
  state = {
    result: ''
  }

  componentDidMount() {
    const parts = window.location.href.split('/')

    axios.default.get(`https://us-central1-waitr-d321d.cloudfunctions.net/findShortUrl?id=${parts[parts.length - 1]}`).then(result => {
      console.log(result.data)
      if (result.data.url) {
        window.location.replace(result.data.url)
      }
    })
  }

  render() {
  return <div></div>
  }
}
